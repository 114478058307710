.InstallerPricesList {
  font-size: $font-size-base * 0.85;

  .special-link {
    font-size: $font-size-base;
  }

  .download-link {
    border: 1px solid transparent;
  }

  .InstallerPricesListGroup {
    .price-group-header {
      font-size: $font-size-base * 1.25;
    }
  }
}
