@use 'common/colors';

$svg-dim: 34px;

.ExternalUnitLocationSelector {
  .minor {
    font-size: $font-size-base * 0.75; // $font-size-base is defined by Bootstrap.
  }

  .IconHolder {
    background-color: color-mix(in srgb, var(--primary), #fff 85%);
    padding: 0.35rem !important;

    .SvgHolder {
      padding: 0.35rem !important;
      margin: 0;
      background-color: white;
      border-radius: 50%;
      display: flex;
      flex-shrink: 0;

      svg {
        height: $svg-dim;
        width: $svg-dim;
        padding-right: 2px;

        .primary {
          fill: $dark !important;
          stroke: none;
        }
      }
    }
  }
}
