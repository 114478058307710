@import 'common/_colors';

.Panels {
  width: 100%;
  height: 100%;

  .topBar {
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 6px;
    background-color: var(--accent);
  }

  .LeftPanel {
    position: fixed;
    height: 100vh;
    background-color: rgba(225, 243, 233, 0.5);
  }

  .RightPanel {
    height: 100vh;
    background-color: white;
  }
}
