@use 'common/colors';

// TODO remove InstantQuote / modal-dialog when we drop old design
.InstantQuote,
.modal-dialog {
  .FormElement .input-container {
    background-color: darken(colors.$form-background, 10);
    border-radius: 4px;

    .form-control {
      border: 0;
      background-color: inherit;
      background-color: colors.$form-background;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &::placeholder {
      color: colors.$placeholder-color;
    }
  }

  .always-spin input[type='number']:not([step='any']) {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      opacity: 1;
    }
  }

  .no-spin input[type='number'] {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }
  }
}
