.Rebates {
  .RebatesList {
    table {
      table-layout: fixed;
      font-size: $font-size-base * 0.75; // $font-size-base is defined by Bootstrap.

      .col-expiration {
        text-wrap: nowrap;
      }

      .col-expiration,
      .col-amount,
      .col-contractor {
        width: 80px;
      }

      .col-actions {
        width: 115px;
      }
    }
  }
}

.ViewRebateSystemsListModal {
  table {
    table-layout: fixed;
    font-size: $font-size-base * 0.75; // $font-size-base is defined by Bootstrap.

    .col-product,
    .col-cold-climate {
      width: 60px;
    }
    .col-brand,
    .col-type {
      width: 100px;
    }
    .col-tons,
    .col-ports,
    .col-coil {
      width: 40px;
    }
  }

  .modal-dialog {
    max-width: 100% !important;
    width: 100% !important;
  }

  .modal-lg {
    max-width: 100% !important;
    width: 100% !important;
  }
}

.ViewRebatesListModal {
  .modal-dialog {
    max-width: 80% !important;
    width: 80% !important;
  }

  .modal-lg {
    max-width: 80% !important;
    width: 80% !important;
  }
}
