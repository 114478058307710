@use 'common/colors';

.Footer {
  display: flex;
  width: 100%;
  padding: 20px 10%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  text-align: center;

  button.back {
    text-decoration: none;
  }

  button.next,
  button.quote {
    min-width: 90px;
    max-width: 150px;
  }
}
