@use 'vars.scss';
@use '../common/sizes';

$accent-padding: 50px;
$quote-width: 320px;

.HeroPanel.Household {
  .Image {
    left: 0;
    top: 10%;
    width: 70%;
    height: 70%;
    img {
      border-radius: 0 $hero-image-radius $hero-image-radius 0;
    }

    .PullQuote {
      width: $quote-width;
      right: -10%;
      top: -10%;
    }

    .Accent {
      width: $quote-width + $accent-padding * 2;
      height: 140%;
      right: calc(-10% - 50px);
      top: -12%;
      border-radius: $accent-radius $accent-radius 0 0;
    }
  }
}
