@use 'vars.scss';
@use '../common/sizes';

.HeroPanel.EnergyInfo {
  overflow: hidden;
  .Image {
    left: 20%;
    top: 10%;
    width: 60%;
    height: 60%;

    .PullQuote {
      width: 100%;
      left: -25%;
      bottom: -15%;
    }

    .Accent {
      right: -60%;
      bottom: -20%;
      left: -20%;
      top: 20%;

      border-radius: $accent-radius 0 0 $accent-radius;
    }

    @media (max-width: sizes.$large-width-breakpoint) {
      left: 0;
      width: 80%;

      .Accent {
        right: -60%;
        bottom: -20%;
        left: -20%;
        top: 20%;
      }

      img {
        border-radius: 0 vars.$hero-image-radius vars.$hero-image-radius 0;
      }

      .PullQuote {
        width: 100%;
        left: 10%;
      }
    }
  }
}
