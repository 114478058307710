@use 'vars.scss';
@use '../common/sizes';

.HeroPanel.Intro {
  .Image {
    top: 21vh;
    width: 50%;
    height: 60%;
    left: vars.$header-left-padding;

    .Accent {
      width: 200%;
      height: 95%;
      left: -40%;
      top: -10%;
    }

    .PullQuote {
      width: 70%;
      right: -35%;
      top: -20%;
    }

    @media (max-width: sizes.$large-width-breakpoint) {
      left: 0;
      width: 80%;

      .Accent {
        width: 110%;
        height: 95%;
        left: 0;
        border-radius: 0 $accent-radius $accent-radius 0;
        top: -10%;
      }

      img {
        border-radius: 0 vars.$hero-image-radius vars.$hero-image-radius 0;
      }

      .PullQuote {
        width: 70%;
        right: -20%;
        top: -20%;
      }
    }
  }
}
