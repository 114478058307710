@use 'common/_colors';
@use 'common/_sizes';

.InstantQuoteHeader {
  background-color: var(--hero-background);
  padding-top: 20px;

  @media (min-width: $tiniest-width-breakpoint) {
    padding-top: 40px;
  }

  @media (min-width: $medium-width-breakpoint) {
    padding-top: 40px;
    background-color: inherit;
  }
}
