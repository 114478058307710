@use 'common/colors';
@use 'common/fonts';

.FormSelector {
  &.fullWidth {
    .form-select {
      max-width: none;
    }
  }
  .form-select {
    max-width: 250px;
    font-size: $default-font-size;
    border: 0;
    background-color: colors.$form-background;
    color: colors.$dark;
  }
}
