@import 'vars.scss';

.HeroPanel.IntroMobile {
  height: 30vh;
  overflow: hidden;

  &.video {
    height: unset;
  }

  .Image {
    left: 0;
    width: 60%;
    height: 100%;

    img {
      border-radius: 0 $hero-image-radius $hero-image-radius 0;
      object-position: center;
    }

    .Accent {
      bottom: -10%;
      left: 50%;
      right: -70%;
      border-radius: $accent-radius 0 0 $accent-radius;
      top: 10%;
    }

    .PullQuote {
      width: 70%;
      left: 60%;
      top: 10%;
      font-weight: 700;

      padding: 25px;
      min-width: 200px;
    }
  }
}
