@use '../common/colors';

.ProjectDetail {
  .detail-label {
    color: colors.$detail-label;
  }

  .btn {
    text-align: left;
    border: 0;
  }
}
