.ProjectSimulator {
  .FormElement,
  .FormRadio {
    width: unset;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem !important; // overrides the mb-4 set by FormElements

    label {
      width: 150px;
    }
    .form-check-label {
      width: unset;
    }
    &.multi-split-room-input label {
      width: unset;
      padding-right: 8px;
      padding-left: 8px;
    }
    &.multi-split-room-input div {
      width: 60px;
    }
    &.unset-child-label-width label {
      width: unset;
    }

    .option {
      background-color: unset;
      padding: unset;
      margin-right: 16px;
    }
  }

  .label {
    width: 150px;
  }

  .label-wrapper {
    height: 50px;
    margin-bottom: 1rem !important; // matches the margin-bottom set above
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .accordion-body {
    padding: 0.5rem 0.75rem;

    table {
      margin-bottom: unset;
    }
  }

  .ProjectLoadCalcAndSelection {
    width: auto;
  }

  .ManualJLoadCalcs {
    &.table {
      width: auto;

      td {
        padding: 0.25rem;
      }
    }
  }

  .SelectionCriteria {
    &.table {
      width: auto;

      td {
        padding: 0.25rem;
      }
    }
  }

  .ProjectTempsAndManualJInputs {
    height: unset;

    .table {
      width: auto;
      height: unset;

      td {
        padding: 0.25rem;
      }
    }
  }
}
