@use 'common/_colors';
@use 'common/_sizes';

.Location {
  .RulerHolder {
    background-color: var(--hero-background);
    width: 62px;
    height: 62px;
  }
  .CombinedAddressAndZipDescription {
    margin-bottom: 1rem !important;

    // desktop
    @media (min-width: $medium-width-breakpoint) {
      margin-bottom: 1.5rem !important;
    }
  }

  .CombinedAddressAndZipInputs {
    $divider-color: colors.$divider-color;

    .line {
      height: 1px;
      background-color: colors.$divider-color;
    }

    // tiniest mobile
    @media (max-width: $tiniest-width-breakpoint) {
      .AutoComplete {
        margin-bottom: 1.15rem !important;
      }
    }
  }
}
