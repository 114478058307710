@use '../common/colors';

.ProjectHero {
  .carousel {
    width: 100%;
    max-width: 150px;
    .carousel-item {
      max-width: 150px;
      width: 100%;
      height: 150px;
    }
  }

  .img-holder {
    width: 100%;
    position: relative;
    height: 50px;
    img {
      object-position: 0;
    }
  }

  .ProjectDetail {
    .description,
    .btn {
      font-weight: bold;
    }
  }
}
