.ListFilterControl {
  .SearchIcon {
    align-self: center;

    svg {
      width: 1.35rem;
      max-width: 1.35rem;
      height: 1.35rem;
      max-height: 1.35rem;
    }
  }

  .ClearFilterButton {
    position: absolute;
    right: 0.5rem;
    top: 0.45rem;
    z-index: 10;
    font-size: $font-size-base * 0.85;
  }
}
