@use '../common/colors';
@use '../common/fonts';

.ProjectStat {
  font-family: $headings-font-family;

  .link-button {
    color: colors.$dark;
  }
}
