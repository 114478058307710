@use '../common/colors';

$validation-width: calc(1.5em + 1.5rem);

$unit-value-width: 70px;
$money-value-width: 110px;

.admin {
  font-family: $font-family-sans-serif;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $font-family-sans-serif;
  }

  .btn-link {
    color: blue;
    padding: 0;
  }

  .form-control {
    max-width: none;
  }

  .form-select {
    width: unset;
    max-width: none;
  }

  .minor {
    font-size: $font-size-base * 0.75; // $font-size-base is defined by Bootstrap.
  }

  .major {
    font-size: $font-size-base * 1.15; // $font-size-base is defined by Bootstrap.
  }

  .modal-dialog {
    font-family: $font-family-sans-serif;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: $font-family-sans-serif;
    }

    .form-control {
      max-width: none;

      &.is-disabled {
        color: $admin-form-input-content-disabled;
        background-color: $admin-form-input-background-disabled;
      }
    }

    .unit-value {
      width: $unit-value-width;

      &.is-valid,
      &.is-invalid {
        width: calc($unit-value-width + $validation-width);
      }
    }

    .money-value {
      width: $money-value-width;
      &.is-valid,
      &.is-invalid {
        width: calc($money-value-width + $validation-width);
      }
    }
  }

  a.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  svg {
    width: 1.35rem;
    max-width: 1.35rem;
    height: 1.35rem;
    max-height: 1.35rem;
  }
}
