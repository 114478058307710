@use '../common/_sizes';

.SystemCarouselPhotos,
.InternalModelOptionsCarouselPhotos {
  .btn-link {
    font-size: $font-size-base * 0.75; // $font-size-base is defined by Bootstrap.
  }

  .SystemCarouselPhoto,
  .InternalModelOptionsCarouselPhoto {
    &.cols2 {
      width: 50%;
    }
    &.cols3 {
      width: 33%;
    }
    &.cols4 {
      width: 25%;
    }
  }
}

.AssignEquipmentPhotosFormModal {
  @media (max-width: $large-width-breakpoint) {
    .modal-dialog {
      max-width: 700px;
    }
  }
}

.AssignEquipmentPhotosFormLine {
  .move-cursor {
    cursor: move;
  }

  .NoPhotoSvg {
    align-self: center;

    svg {
      width: 150px;
      max-width: 150px;
      height: 150px;
      max-height: 150px;
    }
  }
}
