@use 'common/colors';

.ProjectFilterSelector {
  // HEAT_AND_COOL (1)
  // This class name is based on the corresponding ProjectFilter enum value.
  .projectFilter-1 {
    .SvgHolder {
      svg {
        .primary {
          fill: $heat-only-icon-stroke-color;
          stroke: $heat-only-icon-stroke-color;
        }

        .secondary {
          fill: $cool-only-icon-stroke-color;
          stroke: $cool-only-icon-stroke-color;
        }
      }
    }
  }

  // HEAT_ONLY (2)
  // This class name is based on the corresponding ProjectFilter enum value.
  .projectFilter-2 {
    .SvgHolder {
      svg {
        .primary {
          fill: $heat-only-icon-stroke-color;
          stroke: $heat-only-icon-stroke-color;
        }
      }
    }
  }

  // COOL_ONLY (3)
  // This class name is based on the corresponding ProjectFilter enum value.
  .projectFilter-3 {
    .SvgHolder {
      svg {
        .primary {
          fill: $cool-only-icon-stroke-color;
          stroke: $cool-only-icon-stroke-color;
        }
      }
    }
  }
}
