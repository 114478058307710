@use 'common/_colors';
@use 'common/_sizes';

.InstantQuote {
  min-height: 100vh;

  .StepLayout,
  .alert,
  .InstantQuoteHeader {
    padding-right: 5%;
    padding-left: 5%;
  }

  @media (max-width: $tiniest-width-breakpoint) {
    > .container {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
  }

  .arrow {
    display: inline-block;
    // transform: rotate(270deg);

    &.down {
      transform: rotate(90deg);
    }
  }

  @media (min-width: $medium-width-breakpoint) {
    .StepLayout,
    .alert,
    .InstantQuoteHeader {
      padding-right: 10%;
      padding-left: 10%;
    }
  }
}
