// Configuration
@import '~bootstrap/scss/functions';

// 2. Include any default variable overrides here

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';

// 4. Include any default map overrides here
@import '~bootstrap/scss/mixins';

// 5. Include remainder of required parts
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/root';

// Layout & components
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/carousel';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/accordion';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/spinners';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/tables';

// Helpers
@import '~bootstrap/scss/helpers';

// Utilities
@import '~bootstrap/scss/utilities/api';

// ===============================
// Changes copied from https://github.com/twbs/bootstrap/pull/33606/files
// Because updating to Bootstrap 5.2.x or later produced SCSS compilation errors.
.form-check-reverse {
  padding-right: $form-check-padding-start;
  padding-left: 0;
  text-align: right;

  .form-check-input {
    float: right;
    margin-right: $form-check-padding-start * -1;
    margin-left: 0;
  }
}

.form-switch {
  &.form-check-reverse {
    padding-right: $form-switch-padding-start;
    padding-left: 0;

    .form-check-input {
      margin-right: $form-switch-padding-start * -1;
      margin-left: 0;
    }
  }
}
// ===============================

// TODO overriding the color contrast function
@function color-contrast($background) {
  @return $color-contrast-light;
}

.btn,
.btn-close,
.accordion-button {
  &:focus,
  &:active:focus {
    box-shadow: 0 0 0 0.25rem color-mix(in srgb, var(--primary), #fff 80%);
  }
}

.btn-primary {
  background-color: var(--primary);
  border-color: var(--primary);

  &:hover,
  &:active,
  &:focus {
    background-color: var(--primary-alt);
    border-color: var(--primary-alt);
  }

  &:disabled {
    background-color: var(--primary-alt);
    border-color: var(--primary-alt);
  }
}

.btn-secondary {
  background-color: var(--secondary);
  border-color: var(--secondary);

  &:hover,
  &:active,
  &:focus {
    background-color: var(--secondary-alt);
    border-color: var(--secondary-alt);
  }
  &:focus,
  &:active:focus {
    box-shadow: 0 0 0 0.25rem color-mix(in srgb, var(--secondary), #fff 80%);
  }

  &:disabled {
    background-color: var(--secondary-alt);
    border-color: var(--secondary-alt);
  }
}

.btn-link,
.link-button {
  color: var(--link-text) !important;
  text-decoration-color: var(--link-text) !important;

  &.link-primary {
    color: var(--link-primary) !important;
    text-decoration-color: var(--link-primary) !important;
  }

  &:hover,
  &:active,
  &:focus {
    color: var(--link-text-alt) !important;
    text-decoration-color: var(--link-text-alt) !important;

    &.link-primary {
      color: var(--link-primary-alt) !important;
      text-decoration-color: var(--link-text-alt) !important;
    }
  }

  &.disabled {
    color: var(--link-text-disabled) !important;
    text-decoration-color: var(--link-text-disabled) !important;
  }
}

a {
  color: var(--link-text) !important;

  &.link-primary {
    color: var(--link-primary) !important;
  }

  &:hover,
  &:active,
  &:focus {
    color: var(--link-text-alt) !important;

    &.link-primary {
      color: var(--link-primary-alt) !important;
    }
  }

  &.disabled {
    color: var(--link-text-disabled) !important;
    text-decoration-color: var(--link-text-disabled) !important;
  }

  &.eco {
    color: var(--eco) !important;

    &.alt-eco {
      color: var(--eco-alt) !important;
    }

    &:hover,
    &:active,
    &:focus {
      color: var(--eco-alt) !important;
    }
  }
}

.form-control,
.form-select {
  &:focus {
    box-shadow: 0 0 0 0.25rem color-mix(in srgb, var(--primary), #fff 80%);
  }
}

.form-check-input {
  &:checked {
    background-color: var(--primary);
    border-color: var(--primary);
  }
  &:focus {
    box-shadow: 0 0 0 0.25rem color-mix(in srgb, var(--primary), #fff 80%);
  }
}

.border-primary {
  border-color: var(--primary) !important;
}

.link-primary {
  color: var(--link-text);
  &:hover,
  &:active,
  &:focus {
    color: var(--link-text-alt);
  }
}

.nav-link {
  color: var(--primary);
  &:hover,
  &:active,
  &:focus {
    color: var(--primary-alt);
  }
  &:focus-visible {
    box-shadow: 0 0 0 0.25rem color-mix(in srgb, var(--primary), #fff 80%);
  }
}

// scss-docs-end import-stack
