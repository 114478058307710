.PhotosList {
  table,
  tr,
  th,
  td {
    border: 1px solid #dee2e6;
  }

  th {
    text-align: center;
  }

  .col-image {
    margin: auto;
  }

  .col-url {
    padding-left: 4px;
  }
  td.col-url,
  td.col-alt-text {
    font-size: $font-size-base * 0.8; // $font-size-base is defined by Bootstrap.
  }

  .col-alt-text {
    padding-left: 4px;
  }

  .col-actions {
    width: 15%;
    max-width: 15%;
    text-align: center;
  }
}
