@use 'common/_colors';

$border-width: 2px;
$label-padding: 8px;
$label-start-padding: 10px;
$label-end-padding: 8px;
$desktop-label-x-padding: 10px;
$desktop-label-y-padding: 20px;
$input-width: 20px;
$input-right: 20px;
$option-spacing: 5px;
$svg-dim: 34px;

.TypeSelector {
  .IconHolder {
    display: flex;
    height: $svg-dim;
    margin: 20px;

    .SvgHolder {
      svg {
        height: $svg-dim;
        max-width: 100%;
        min-width: $svg-dim;
        flex-shrink: 0;

        .primary {
          fill: colors.$dark;
        }

        .primary-stroke {
          stroke: colors.$dark;
        }
      }
    }
  }

  &.compact {
    .IconHolder {
      display: flex;
      height: $svg-dim;
      margin: 0;
      margin-right: 10px;
      background-color: white;
      border-radius: 50vh;

      .SvgHolder {
        max-width: $svg-dim;
        height: $svg-dim;
        margin: 0;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        svg {
          height: 20px;
        }
      }
    }

    .option {
      padding: 10px ($label-end-padding + $input-width + $input-right) 10px $label-start-padding;
      &.selected {
        padding: (10px - $border-width) ($label-end-padding + $input-width + $input-right - $border-width)
          (10px - $border-width) ($label-start-padding - $border-width);
      }
    }
  }

  input {
    position: absolute;
    right: $input-right;
    top: 50%;
    width: $input-width;
    height: $input-width;
    transform: translateY(-50%);
  }

  label {
    display: flex;
    align-items: center;
    width: 100%;
    padding: ($border-width + $label-padding) $border-width;
    padding-right: $border-width + $input-right + $input-width;
    background-color: colors.$unselected-radio;

    &.selected {
      padding: $label-padding 0;
      padding-right: $input-right + $input-width;
      background-color: var(--selected-radio);
    }
  }

  .form-check {
    padding: 0;
    position: relative;

    .form-check-input {
      margin: 0;
    }
  }

  @media (min-width: $medium-width-breakpoint) {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -$option-spacing;

    .description {
      word-break: break-word;
    }

    .form-check {
      padding: 0 $option-spacing;
    }

    &.large {
      justify-content: space-evenly;
      text-align: center;

      .description {
        text-align: center;
      }

      .form-check {
        justify-content: center;

        .form-check-label {
          &:hover,
          &.selected {
            background-color: var(--selected-radio);
          }
        }
      }

      label {
        display: block;
        width: auto;
      }

      input {
        position: absolute;
        right: 25px;
        top: 15px;
        transform: none;
      }

      .option {
        padding: $desktop-label-y-padding $desktop-label-x-padding;
        height: 100%;
        min-height: 100px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &.selected {
          padding: ($desktop-label-y-padding - $border-width) ($desktop-label-x-padding - $border-width);
        }
      }

      .SvgHolder {
        margin: 20px 0;
      }
    }
  }
}
