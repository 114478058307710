@use '../common/colors';

.ProjectFeature {
  .icon {
    width: 32px;
    height: 32px;

    .primary {
      fill: colors.$feature-icon-primary;
    }
  }
}
