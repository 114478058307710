$logo-height: 96px;
$small-logo-height: 96px;
$svg-height: 32px;

.LogoHeaderItem {
  flex: 0 0 auto;
  a {
    display: block;
    width: auto;
    height: auto;
    svg {
      width: auto;
      max-width: 100%;
      height: $svg-height;
    }
    img {
      position: relative !important;
      height: auto !important;
      width: 100%;
      max-height: $logo-height;
      object-position: bottom left;
      background-color: white;
    }
  }
  &.small {
    img {
      max-height: $small-logo-height;
      object-fit: contain;
      object-position: center;
    }
  }
}
