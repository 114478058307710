@import 'vars.scss';

.HeroPanel.Location {
  .Image {
    left: -$hero-image-radius;
    top: 15vh;
    width: 80%;
    height: 60vh;

    .PullQuote {
      width: 50%;
      right: -10%;
      bottom: -10%;
    }

    .Accent {
      min-width: 320px;
      width: 70%;
      height: 140%;
      right: -20%;
      top: -20%;
    }
  }
}
