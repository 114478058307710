.SystemDetails {
  .btn-link {
    font-size: $font-size-base * 0.75; // $font-size-base is defined by Bootstrap.
  }
}

.ViewCapacityRatingsListModal {
  .modal-dialog {
    max-width: 80% !important;
    width: 80% !important;
  }

  .modal-lg {
    max-width: 80% !important;
    width: 80% !important;
  }
}
