@import '../common/_colors';
@import 'vars.scss';

.HeroPanel {
  width: 100%;
  height: 100%;
  background-color: var(--hero-background);

  &.partner .header {
    background-color: white;
  }

  .Image {
    position: relative;
    img {
      border-radius: $hero-image-radius;
      object-fit: cover;
    }

    .PullQuote {
      position: absolute;
      background: $quote-background;
      border-radius: $quote-radius;
      min-width: 260px;
      padding: 40px;

      .quote {
        line-height: 175%;
      }

      .attribution {
        line-height: 150%;
        color: rgba($primary, 0.9);
      }
    }

    .Accent {
      position: absolute;
      background: var(--left-panel-accent);
      border-radius: $accent-radius;
    }
  }

  .Video {
    position: relative;
    top: 10vh;
    width: 62%;
    margin-left: auto;
    margin-right: auto;

    &.mobile {
      top: unset;
      width: 88%;
      padding-top: 3vw;
      padding-bottom: 6vw;
    }

    .yt-lite {
      border-radius: $quote-radius / 2;
    }

    .PullQuote {
      position: relative;
      margin-top: 2.5vh;
      background: $quote-background;
      border-radius: $quote-radius / 2;
      width: 84%;
      min-width: 300px;
      padding: min(40px, 2vw);
      margin-left: auto;
      margin-right: auto;

      .quote {
        line-height: 175%;
      }

      .attribution {
        line-height: 150%;
        color: rgba($primary, 0.9);
      }
    }

    .Accent {
      position: absolute;
      background: var(--left-panel-accent);
      border-radius: $accent-radius / 2;
      top: -8%;
      left: -12%;
      width: 126%;
      height: 115%;
    }

    &.mobile .Accent {
      width: 146%;
    }
  }
}
