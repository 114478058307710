@use '../common/colors';

.PriceBreakout {
  .accordion-item {
    border-bottom: 0;

    .accordion-button {
      &:not(.collapsed) {
        box-shadow: none;
        color: var(--primary); // Remove after we change the primary colors
      }
    }
  }
}
