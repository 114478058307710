@import 'common/_colors';
@import 'common/_fonts';
@import 'common/_sizes';

$input-padding-y: 0.75rem;
$font-sizes: (
  1: 1.7rem,
  2: 1.5rem,
  3: 1.3rem,
  4: 1.1rem,
  5: 0.9rem,
  6: 0.7rem,
);
@import 'common/_bootstrap';

@import 'results/index.scss';

@import 'carousel.scss';
@import 'DuctedPreview.scss';
@import 'Footer.scss';
@import 'FormTextArea.scss';
@import 'FormElement.scss';
@import 'FormRadio.scss';
@import 'FormSelector.scss';
@import 'HVACData.scss';
@import 'InfoBox.scss';
@import 'InstantQuote.scss';
@import 'InstantQuoteHeader.scss';
@import 'LayoutPreview.scss';
@import 'Location.scss';
@import 'Household.scss';
@import 'LogoHeaderItem.scss';
@import 'MultiSplitRoomData.scss';
@import 'heroPanels/index.scss';
@import 'Panels.scss';
@import 'SingleRoomData.scss';
@import 'TypeSelector.scss';
@import 'WizardNav.scss';
@import 'ProjectFilterSelector.scss';
@import 'ExternalUnitLocationSelector.scss';

@import 'admin/index.scss';
@import 'admin/EquipmentCarouselPhotos.scss';
@import 'admin/SystemDetails.scss';
@import 'admin/SystemSummary.scss';
@import 'admin/ModelDetails.scss';
@import 'admin/PhotosList.scss';
@import 'admin/ListFilterControl.scss';
@import 'admin/RebatesList.scss';
@import 'admin/EditInstallerRebatesFormModal.scss';
@import 'admin/ProjectSimulator.scss';
@import 'admin/InstallerPricesList.scss';
@import 'admin/InstallerPricesSummary.scss';
@import 'admin/PricedEquipmentList.scss';

:root,
[data-bs-theme='light'] {
  --primary: #43976a;
  --primary-rgb: 67, 151, 106;

  --eco: #43976a;

  --secondary: #05a6e1;
  --secondary-rgb: 5, 166, 225;

  --tertiary: #9cdcfc;
  --tertiary-rgb: 156, 220, 252;

  --link-primary: var(--primary);
  --link-text: var(--primary);
  --accent: var(--primary);

  --divider: #e3e3e3;

  --primary-alt: color-mix(in srgb, var(--primary), #fff 20%);
  --link-text-alt: color-mix(in srgb, var(--link-text), #fff 20%);
  --link-text-disabled: color-mix(in srgb, var(--link-text), #000 20%);
  --link-primary-alt: var(--primary-alt);
  --eco-alt: color-mix(in srgb, var(--eco), #fff 20%);
  --secondary-alt: color-mix(in srgb, var(--secondary), #fff 20%);
  --tertiary-alt: color-mix(in srgb, var(--tertiary), #000 20%);
  --light: #{$light};
  --left-panel-accent: color-mix(in srgb, var(--accent) 5%, transparent);
  --hero-background: color-mix(in srgb, var(--accent), #fff 97%);
  --selected-radio: color-mix(in srgb, var(--primary), #fff 97%);
  --contact-background: color-mix(in srgb, var(--primary), #fff 95%);

  --bs-primary: var(--primary);
  --bs-primary-rgb: var(--primary);
  --bs-secondary: var(--secondary);
  --bs-secondary-rgb: var(--secondary);
  --bs-link-color: var(--link-text);
  --bs-link-hover-color: var(--link-text-alt);
}

.btn-primary {
  --bs-btn-bg: var(--primary);
  --bs-btn-border-color: var(--primary);
  --bs-btn-hover-bg: var(--primary-alt);
  --bs-btn-hover-border-color: var(--primary-alt);
  --bs-btn-active-bg: var(--primary-alt);
  --bs-btn-active-border-color: var(--primary-alt);
  --bs-btn-disabled-bg: var(--primary-alt);
  --bs-btn-disabled-border-color: var(--primary-alt);
  --bs-btn-focus-shadow-rgb: var(--primary-rgb);
}

.btn-secondary {
  --bs-btn-bg: var(--secondary);
  --bs-btn-border-color: var(--secondary);
  --bs-btn-hover-bg: var(--secondary-alt);
  --bs-btn-hover-border-color: var(--secondary-alt);
  --bs-btn-active-bg: var(--secondary-alt);
  --bs-btn-active-border-color: var(--secondary-alt);
  --bs-btn-disabled-bg: var(--secondary-alt);
  --bs-btn-disabled-border-color: var(--secondary-alt);
  --bs-btn-focus-shadow-rgb: var(--secondary-rgb);
}

html,
body,
#root {
  height: 100%;
}

.InstantQuote,
.modal-dialog {
  .hidden {
    visibility: hidden;
  }

  font-family: $font-family-sans-serif;
  font-size: $default-font-size;
  color: $dark;

  .btn {
    font-size: $default-font-size;
  }

  .rounded {
    border-radius: 8px !important;
  }

  .text-primary {
    color: rgba(var(--primary), 0.9);
  }

  .text-muted {
    color: $muted !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $headings-font-family;
  }

  .heading-font {
    font-family: $headings-font-family;
  }

  h1 {
    font-size: 1.2rem !important;
  }

  h2 {
    font-size: 1.1rem !important;
  }

  .link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;
  }

  svg {
    .primary {
      fill: var(--primary);
    }
    .alt-primary {
      fill: var(--primary-alt);
    }
    .eco {
      fill: var(--eco);
    }
    .alt-eco {
      fill: var(--eco-alt);
    }
    .secondary {
      fill: var(--secondary);
    }
    .alt-secondary {
      fill: var(--secondary-alt);
    }
    .tertiary {
      fill: var(--tertiary);
    }
    .alt-tertiary {
      fill: var(--tertiary-alt);
    }

    &.large {
      width: 100px;
      max-height: 100px;
    }
  }

  .icon {
    &.large {
      width: 100px;
      max-height: 100px;
    }
  }

  @media (min-width: $medium-width-breakpoint) {
    .md-visible {
      visibility: visible !important;
    }
  }

  // Undo carousel control z-index
  .carousel-control-prev,
  .carousel-control-next {
    z-index: inherit;
  }

  .hide-placeholder {
    // Based on https://stackoverflow.com/a/30795020
    ::-webkit-input-placeholder {
      /* WebKit browsers */
      color: transparent;
    }
    :-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: transparent;
    }
    ::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: transparent;
    }
    :-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: transparent;
    }
    input::placeholder {
      color: transparent;
    }
    textarea::-webkit-input-placeholder {
      /* WebKit browsers */
      color: transparent;
    }
    textarea:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: transparent;
    }
    textarea::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: transparent;
    }
    textarea:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: transparent;
    }
    textarea::placeholder {
      color: transparent;
    }
  }
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    break-before: auto;
  }
}

.printButton {
  font-family: $font-family-sans-serif !important;
}

.hideWhenPrinting {
  @media print {
    display: none;
  }
}
