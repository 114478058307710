@use '../common/colors';

.ProjectBody {
  .nav-tabs {
    .nav-link {
      border: 0;

      &:hover,
      &:focus {
        border-bottom: var(--primary) 3px solid;
      }

      &.active {
        border-bottom: colors.$dark 3px solid;
        font-weight: 700;
      }
    }
  }
}
