@use 'common/_colors';
@use 'common/_sizes';

.HouseholdData {
  .optional {
    border-left: 2px solid colors.$divider-color;
    padding-left: 25px;
  }

  .PiggyHolder {
    background-color: var(--hero-background);
    width: 62px;
    height: 62px;
  }

  .highlight {
    border: 1px solid colors.$divider-color;
  }

  .HomeRebatePreview {
    --bs-gutter-x: 1.5rem;
    margin-left: calc(-0.5 * var(--bs-gutter-x));

    .title {
      color: var(--primary);
    }
  }
}
