@use '../common/colors';

.ProjectRating {
  .label {
    color: colors.$rating-label;
  }

  .rating-holder {
    background-color: colors.$rating-background;
    position: relative;
    height: 6px;

    .rating {
      position: absolute;
      background-color: var(--primary);

      @for $val from 1 through 5 {
        $width: percentage($val/5);

        &.amount-#{$val} {
          width: $width;
        }
      }
    }
  }
}
