@use 'common/_colors';

$border-width: 2px;
$label-padding: 10px;
$input-width: 20px;
$input-right: 20px;

.FormRadio {
  .option {
    padding: ($border-width + $label-padding) (2 * $label-padding + $border-width);
    background-color: colors.$unselected-radio;

    &.selected {
      padding: $label-padding (2 * $label-padding);
      background-color: var(--selected-radio);
    }
  }

  input {
    width: $input-width;
    height: $input-width;
  }

  .form-check-input {
    margin: 0;
  }
}
