@use 'common/_colors';
@use 'common/_sizes';

$svg-height: 24px;

.HVACData {
  .optional {
    border-left: 2px solid colors.$divider-color;
    padding-left: 25px;

    .thermostat-title {
      line-height: $svg-height;
    }

    svg {
      height: $svg-height;
      flex-shrink: 0;
    }
  }
}
