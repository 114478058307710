.EditInstallerRebatesFormModal {
  .dragDropColumn {
    min-width: 40%;
    max-width: 50%;
  }

  .modal-dialog {
    max-width: 100% !important;
    width: 100% !important;
  }

  .modal-lg {
    max-width: 100% !important;
    width: 100% !important;
  }
}
