$dark: #333333;
$unselected-radio: #f8f8f8;
$muted: #888888;
$hero-background: #f3faf6;
$divider-color: #e3e3e3;

$form-background: #f4f4f4;
$placeholder-color: #999999;
$feature-icon-primary: #544e4e;
$detail-label: #777777;
$rating-label: #666666;
$rating-background: #d9d9d9;

$admin-form-input-content-disabled: $dark;
$admin-form-input-background-disabled: #fff;

$heat-only-icon-stroke-color: #ff4d55;
$cool-only-icon-stroke-color: #05a6e1;
